<template>
    <basic-layout class="ma-4">
        <v-container class="fill-height" fluid>
            <app-bar :title="title"/>
            <v-row align="center" justify="center" class="ma-1">
                <v-col cols="12" sm="8" md="4">
                    <main-title/>
                    <div class="font-weight-light primary--text mb-6 text-center">
                        <span  class="font-weight-light primary--text subheading">Reset your password</span>
                        <p v-if="(!errors || !alert.text)" class="mt-3" >Please input your registered email.</p>
                        <p v-else class="mt-3" >{{errors}}</p>
                        <v-alert v-if="alert.text" dense text :type="alert.type">
                            {{alert.text}}
                        </v-alert>
                    </div>
                    <v-form v-model="valid" ref="form">
                        <v-text-field  :rules="rules.emailRules" label="Email"  v-model="form.email"  prepend-icon="mdi-email" type="text"/>
                        <v-btn block type="submit" class="mt-5" :loading="sending" :disabled="sending" color="primary" @click.prevent="send_request">
                            Send Password Reset Link
                            <template v-slot:loader>
                                <span>Please wait...</span>
                            </template>
                        </v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </basic-layout>
</template>

<script>
    import user from "../../api/user"
    export default {
        name: "ForgotPassword",
        data(){
            return{
                title: 'Forgot Password',
                valid: false,
                sending: false,
                form: {
                    email: '',
                },
                rules:{
                    emailRules: [
                        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Please provide a valid email',
                        v => !!v || 'This field is required',
                    ],
                },
                errors: null,
            }
        },
        computed: {
            alert(){
                return this.$store.getters.getAlert;
            }
        },
        methods: {
            async send_request(){
                if(this.$refs.form.validate()){
                      this.sending = true;
                   await user.forgot_password(this.form)
                        .then(response => {
                            this.sending = false;
                            this.$store.dispatch('setAlert',response.data);
                            this.$router.push({name: 'Welcome'});
                        })
                        .catch(error =>{
                            this.$store.dispatch('setAlert', {
                                text: error.response.data.errors.email[0],
                                type: 'error',
                            });
                            this.sending = false;
                            this.errors = error.data;
                        })
                    this.sending = false;
                }


            }
        }
    }
</script>

<style scoped>

</style>